<template>
  <z-section-divider
    v-if="Object.keys(config.additionalData).length > 0"
    :title="$t('reservation.additional_info')"
    start-opened
  >
    <template #content>
      <p class="body2 mb-5">{{ $t("reservation.hotel_data_info") }}</p>

      <div class="extra-fields">
        <template v-for="(field, index) of config.additionalData">
          <dynamic-field
            :id="field.id"
            :key="index"
            :title="field.label"
            :mask="field.mask"
            :type="field.type"
            :disabled="disabled"
            :html="field.text"
            :max-rate="+field.max"
            :options="
              Array.isArray(field.dataSource)
                ? field.dataSource.map((f) => ({ label: f.name, value: f.val }))
                : []
            "
            :value="guest.additionalData[field.id]"
            :error-messages="getErrorMessages(v$.additionalData[field.id])"
            :class="{ 'full-line': !/^(text|number|date)$/.test(field.type) }"
            @change="onChange"
          />
        </template>
      </div>
    </template>
  </z-section-divider>
</template>

<script>
import DynamicField from "../DynamicField.vue"

export default {
  components: { DynamicField },
  props: {
    guest: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
    getErrorMessages: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const onChange = (chunk) => {
      const newChunk = Object.keys(chunk).reduce((obj, key) => {
        return { ...obj, [`additionalData.${key}`]: chunk[key] }
      }, {})

      emit("change", newChunk)
    }

    return {
      onChange,
    }
  },
}
</script>

<style lang="scss" scoped>
.extra-fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  .full-line {
    grid-column: 1 / -1;
  }
}
</style>
