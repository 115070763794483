import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
} from "@vuelidate/validators"
import { computed } from "vue"

import { useValidations } from "@/composables"

// eslint-disable-next-line
export function useAdditionalInfoValidations({ config, guest }) {
  const { withI18nMessage, getErrorMessages } = useValidations()

  /**
   * Validações dos dados pessoais
   */
  // eslint-disable-next-line
  const travelDataRules = computed(() => {
    const validationRules = {
      arrivingFromCountryId: {},
      arrivingFromStateId: {},
      arrivingFrom: {},
      nextDestinationCountryId: {},
      nextDestinationStateId: {},
      nextDestination: {},
      purposeTrip: {},
      arrivingBy: {},
    }

    const isArrivingFromBr = guest.value.arrivingFromCountryId === "BR"
    const hasDestinyBr = guest.value.nextDestinationCountryId === "BR"

    if (!config.travelData?.arrivingFromCountry?.default) {
      validationRules.arrivingFromCountryId = {
        required: withI18nMessage(required),
      }
    }

    if (!config.travelData?.arrivingFromState?.default && isArrivingFromBr) {
      validationRules.arrivingFromStateId = {
        required: withI18nMessage(required),
      }
    }

    if (!config.travelData?.arrivingFromCity?.default && isArrivingFromBr) {
      validationRules.arrivingFrom = {
        required: withI18nMessage(required),
      }
    }

    if (!config.travelData?.nextDestinationCountry?.default) {
      validationRules.nextDestinationCountryId = {
        required: withI18nMessage(required),
      }
    }

    if (!config.travelData?.nextDestinationState?.default && hasDestinyBr) {
      validationRules.nextDestinationStateId = {
        required: withI18nMessage(required),
      }
    }

    if (!config.travelData?.nextDestinationCity?.default && hasDestinyBr) {
      validationRules.nextDestination = {
        required: withI18nMessage(required),
      }
    }

    if (!config.travelData?.purposeTrip?.default) {
      validationRules.purposeTrip = {
        required: withI18nMessage(required),
      }
    }

    if (!config.travelData?.transportType?.default) {
      validationRules.arrivingBy = {
        required: withI18nMessage(required),
      }
    }

    return validationRules
  })

  /**
   * Validações de endereço
   */
  // eslint-disable-next-line
  const extraDataRules = computed(() => {
    const validationRules = {}

    for (let field of config.additionalData) {
      const key = field.id
      const isTypeText = field.type === "text"
      const isTypeNumber = field.type === "number"
      const isRequired = !!field.required

      validationRules[key] = {}

      if (isRequired) {
        validationRules[key]["required"] = withI18nMessage(required)
      }

      if (isTypeNumber) {
        if (!Number.isNaN(field.min)) {
          validationRules[key]["minValue"] = withI18nMessage(
            minValue(field.min)
          )
        }

        if (field.max !== null) {
          validationRules[key]["maxValue"] = withI18nMessage(
            maxValue(field.max)
          )
        }
      }

      if (isTypeText) {
        const hasMinLength = !Number.isNaN(field.min)
        const hasMaxLength = !Number.isNaN(field.max)

        if (hasMinLength) {
          validationRules[key]["minLength"] = withI18nMessage(
            minLength(field.min)
          )
        }

        if (hasMaxLength) {
          validationRules[key]["maxLength"] = withI18nMessage(
            maxLength(field.max)
          )
        }
      }
    }

    return { additionalData: validationRules }
  })

  return {
    travelDataRules,
    extraDataRules,
    getErrorMessages,
  }
}
