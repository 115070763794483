<template>
  <z-input
    v-if="type === 'text' && mask && mask[currentLang]"
    :label="currentLabel"
    :value="value"
    :mask="mask[currentLang]"
    :error-messages="errorMessages"
    :disabled="disabled"
    @input="$emit('change', { [id]: $event })"
  />
  <z-input
    v-else-if="type === 'text'"
    :label="currentLabel"
    :value="value"
    :error-messages="errorMessages"
    :disabled="disabled"
    @input="$emit('change', { [id]: $event })"
  />
  <z-input
    v-else-if="type === 'number'"
    type="number"
    :label="currentLabel"
    :error-messages="errorMessages"
    :disabled="disabled"
    :value="value"
    @input="$emit('change', { [id]: $event })"
  />
  <z-checkbox
    v-else-if="type === 'checkbox'"
    :label="currentLabel"
    :error-messages="errorMessages"
    :disabled="disabled"
    :value="!!value"
    @change="$emit('change', { [id]: $event })"
  />
  <div v-else-if="type === 'rate'">
    <p>{{ currentLabel }}</p>

    <z-rating
      :value="value || 0"
      :length="maxRate"
      :size="24"
      @input="$emit('change', { [id]: $event })"
    />

    <p class="error-messages">
      {{ errorMessages.length > 0 ? errorMessages[0] : "" }}
    </p>
  </div>
  <z-input-date
    v-else-if="type === 'date'"
    eager
    :label="currentLabel"
    :error-messages="errorMessages"
    :disabled="disabled"
    :value="value"
    @input="$emit('change', { [id]: $event })"
  />

  <div v-else-if="type === 'select'">
    <p>{{ currentLabel }}</p>
    <z-radio-group
      :value="value"
      :disabled="disabled"
      :error-messages="errorMessages"
      :items="options"
      @change="$emit('change', { [id]: $event })"
    />
  </div>
  <div v-else-if="type === 'text-data'" v-html="html[currentLang]"></div>
</template>

<script>
import { computed, toRefs } from "vue-demi"

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String, Number, Boolean],
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Object,
      default: () => ({}),
    },
    html: {
      type: Object,
      default: () => ({}),
    },
    currentLang: {
      type: String,
      default: "pt",
    },
    options: {
      type: Array,
      default: () => [],
    },
    mask: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "text",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    maxRate: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  setup(props) {
    const { currentLang, required, title } = toRefs(props)

    const currentLabel = computed(() => {
      return required.value
        ? title.value[currentLang.value]
        : title.value[currentLang.value]
    })

    return { currentLabel }
  },
}
</script>
