<template>
  <z-section-divider
    v-if="
      !config.travelData?.arrivingFromCountry?.default ||
      !config.travelData?.arrivingFromState?.default ||
      !config.travelData?.arrivingFromCity?.default ||
      !config.travelData?.nextDestinationCountry?.default ||
      !config.travelData?.nextDestinationState?.default ||
      !config.travelData?.nextDestinationCity?.default ||
      !config.travelData?.purposeTrip?.default ||
      !config.travelData?.transportType?.default
    "
    :title="$t('reservation.about_travel_data')"
    start-opened
  >
    <template #content>
      <p class="body2 mb-5">
        {{ $t("reservation.travel_data_info") }}
      </p>
      <div class="section-container">
        <p
          v-if="
            !config.travelData?.arrivingFromCountry?.default ||
            !config.travelData?.arrivingFromState?.default
          "
          class="body-bold mb-4"
        >
          {{ $t("reservation.origin_section") }}
        </p>
        <div class="select-container">
          <z-autocomplete
            v-if="!config.travelData?.arrivingFromCountry?.default"
            data-cy="origin-country"
            :label="$t('reservation.country')"
            :items="countries"
            :item-text="currentLangItemText"
            item-value="code"
            :autocomplete="Math.random()"
            :disabled="disabled"
            :error-messages="getErrorMessages(v$.arrivingFromCountryId)"
            :value="guest.arrivingFromCountryId"
            @change="
              $emit('change', {
                arrivingFromCountryId: $event,
              })
            "
          />
          <template v-if="!config.travelData?.arrivingFromState?.default">
            <z-autocomplete
              v-if="guest.arrivingFromCountryId === 'BR'"
              data-cy="origin-state"
              :label="$t('reservation.state')"
              :autocomplete="Math.random()"
              :items="statesOptions.origin"
              item-text="name"
              item-value="id"
              :disabled="disabled"
              :error-messages="getErrorMessages(v$.arrivingFromStateId)"
              :value="guest.arrivingFromStateId"
              @change="
                $emit('change', {
                  arrivingFromStateId: $event,
                })
              "
            />
            <z-input
              v-else
              :disabled="disabled"
              :label="$t('reservation.optional_state')"
              :error-messages="getErrorMessages(v$.arrivingFromStateName)"
              :value="guest.arrivingFromStateName"
              @change="$emit('change', { arrivingFromStateName: $event })"
            />
          </template>

          <template v-if="!config.travelData?.arrivingFromCity?.default">
            <z-autocomplete
              v-if="guest.arrivingFromCountryId === 'BR'"
              data-cy="origin-city"
              :label="$t('reservation.city')"
              :autocomplete="Math.random()"
              :items="citiesOptions.origin"
              item-text="name"
              item-value="id"
              :disabled="disabled"
              :error-messages="getErrorMessages(v$.arrivingFrom)"
              :value="guest.arrivingFrom"
              @change="$emit('change', { arrivingFrom: $event })"
            />
            <z-input
              v-else
              :disabled="disabled"
              :label="$t('reservation.optional_city')"
              :error-messages="getErrorMessages(v$.arrivingFromName)"
              :value="guest.arrivingFromName"
              @change="$emit('change', { arrivingFromName: $event })"
            />
          </template>
        </div>
      </div>
      <div class="section-container">
        <p
          v-if="
            !config.travelData?.nextDestinationCountry?.default ||
            !config.travelData?.nextDestinationState?.default
          "
          class="body-bold mb-4"
        >
          {{ $t("reservation.next_destination_section") }}
        </p>
        <div class="select-container">
          <z-autocomplete
            v-if="!config.travelData?.nextDestinationCountry?.default"
            data-cy="next-country"
            :label="$t('reservation.country')"
            :items="countries"
            :autocomplete="Math.random()"
            :disabled="disabled"
            :item-text="currentLangItemText"
            item-value="code"
            :error-messages="getErrorMessages(v$.nextDestinationCountryId)"
            :value="guest.nextDestinationCountryId"
            @change="
              $emit('change', {
                nextDestinationCountryId: $event,
              })
            "
          />
          <template v-if="!config.travelData?.nextDestinationState?.default">
            <z-autocomplete
              v-if="guest.nextDestinationCountryId === 'BR'"
              data-cy="next-state"
              :autocomplete="Math.random()"
              :label="$t('reservation.state')"
              :items="statesOptions.destiny"
              :disabled="disabled"
              item-text="name"
              item-value="id"
              :error-messages="getErrorMessages(v$.nextDestinationStateId)"
              :value="guest.nextDestinationStateId"
              @change="
                $emit('change', {
                  nextDestinationStateId: $event,
                })
              "
            />
            <z-input
              v-else
              :label="$t('reservation.optional_state')"
              :disabled="disabled"
              :error-messages="getErrorMessages(v$.nextDestinationStateName)"
              :value="guest.nextDestinationStateName"
              @change="$emit('change', { nextDestinationStateName: $event })"
            />
          </template>
          <template v-if="!config.travelData?.nextDestinationCity?.default">
            <z-autocomplete
              v-if="guest.nextDestinationCountryId === 'BR'"
              data-cy="next-city"
              :autocomplete="Math.random()"
              :label="$t('reservation.city')"
              :items="citiesOptions.destiny"
              :disabled="disabled"
              item-text="name"
              item-value="id"
              :error-messages="getErrorMessages(v$.nextDestination)"
              :value="guest.nextDestination"
              @change="$emit('change', { nextDestination: $event })"
            />
            <z-input
              v-else
              :label="$t('reservation.optional_city')"
              :error-messages="getErrorMessages(v$.nextDestinationName)"
              :disabled="disabled"
              :value="guest.nextDestinationName"
              @change="$emit('change', { nextDestinationName: $event })"
            />
          </template>
        </div>
      </div>
      <div class="select-container">
        <div
          v-if="!config.travelData?.transportType?.default"
          class="sectin-container"
        >
          <p class="body-bold mb-4">
            {{ $t("reservation.transport_type") }}
          </p>
          <div class="select-container">
            <z-select
              data-cy="transport-type"
              :label="$t('reservation.transport')"
              :error-messages="getErrorMessages(v$.arrivingBy)"
              :disabled="disabled"
              :value="guest.arrivingBy"
              :items="transportTypes"
              :autocomplete="Math.random()"
              :item-text="currentLangItemText"
              item-value="id"
              @change="$emit('change', { arrivingBy: $event })"
            />
          </div>
        </div>
        <div
          v-if="!config.travelData?.purposeTrip?.default"
          class="section-container"
        >
          <p class="body-bold mb-4">
            {{ $t("reservation.travel_purpose") }}
          </p>
          <div class="select-container">
            <z-select
              data-cy="travel-purpose"
              :label="$t('reservation.travel_purpose')"
              :items="purposes"
              :disabled="disabled"
              :autocomplete="Math.random()"
              :item-text="currentLangItemText"
              item-value="id"
              :error-messages="getErrorMessages(v$.purposeTrip)"
              :value="guest.purposeTrip"
              @change="$emit('change', { purposeTrip: $event })"
            />
          </div>
        </div>
      </div>
    </template>
  </z-section-divider>
</template>

<script>
import { computed } from "vue"
import { useNamespacedState } from "vuex-composition-helpers/dist"

export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    statesOptions: {
      type: Object,
      default: () => {},
    },
    citiesOptions: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    transportTypes: {
      type: Array,
      default: () => [],
    },
    purposes: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
    getErrorMessages: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const { currentLang } = useNamespacedState("layout", ["currentLang"])

    const currentLangItemText = computed(() => {
      return currentLang.value === "en" ? "name" : `name_${currentLang.value}`
    })

    return { currentLangItemText }
  },
}
</script>

<style lang="scss" scoped>
.section-container {
  display: flex;
  flex-direction: column;
}

.select-container {
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
  justify-content: start;
}
</style>
